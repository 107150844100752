/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "English",
  es: "Español",
}

const Language = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <div
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                color: `black`,
                backgroundColor: currentLocale === language ? `#ffde00` : `white`,
                margin: 10,
                display: 'inline-block',
                textDecoration: `none`,
                cursor: `pointer`,
                border: 2,
                borderRadius: 5,
                borderStyle: 'solid',
                borderColor: currentLocale === language ? `#ffde00` : `black`,
                padding: '10px 20px'
              }}
            >
              {languageName[language]}
            </div>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language
