import { Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"
import Language from "./language"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
    }}
  >
    <div
      style={{ display: `flex`,
       flex: 1,
      alignItems: 'flex-end', 
      justifyContent: 'center', 
      flexDirection: 'column', 
      padding: '1rem 1rem'
    }}
    >
      <div
        style={{
          float: `right`,
        }}
      >
        <Language />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
